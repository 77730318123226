import { get, isEmpty } from 'lodash';
import numeral from 'numeral';
import getMemberPrice from './get-member-price';
import ProductType from '../../Constants/ProductType';

export default function makePrice(line, product, plan) {
  if (line?.stripeId) {
    return numeral(line.amount / 100).format('$0,000.00');
  }

  if (product.productType === ProductType.PRINT) {
    const price = line.id
      ? line.amount / line.quantity
      : get(line, 'print.amount') / get(line, 'print.quantity');
    return numeral(price / 100).format('$0,000.00');
  }

  const productPrice = !isEmpty(plan)
    ? getMemberPrice(product, plan)
    : get(product, 'price');

  return numeral((productPrice * line.quantity) / 100).format('$0,000.00');
}
