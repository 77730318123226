import React, { useState } from 'react';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import FlagSVG from '../../../components/FlagSVG/FlagSVG';
import { Typography } from '@mui/material';
import PriceCalculatorForm from './PriceCalculatorForm';
import PriceCalculatorOutput from './PriceCalculatorOutput';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  bg: {
    width: '100%',
    height: '100%',
    maxHeight: '640px',
    maxWidth: '100%',
    overflow: 'hidden',
    padding: '8px 16px',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      maxHeight: '882px'
    }
  },
  bgImg: {
    borderRadius: '24px',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    objectPosition: 'center top',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  },
  calculator: {
    margin: '8px 16px',
    overflow: 'hidden',
    zIndex: 1
  },
  calculatorInner: {
    width: '1260px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      padding: '0px 24px'
    }
  },
  footnote: {
    color: theme.palette.bg.main,
    marginTop: '12px',
    maxWidth: '360px'
  },
  header: {},
  headerFlag: {
    height: '64px',
    transform: 'rotate(270deg) translate(-64px, 0px)',
    transformOrigin: 'top left',
    [theme.breakpoints.down('md')]: {
      height: '44px',
      transform: 'rotate(270deg) translate(-44px, 0px)'
    }
  },
  headerTitle: {
    color: theme.palette.bg.main
  },
  root: {
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 35%, ${theme.palette.bg.main} 100%)`,
    padding: '72px 0px 144px 0px',
    position: 'relative',
    transform: 'translateZ(0px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '36px 0px 72px 0px'
    }
  }
}));

export default function PriceCalculator(props) {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    numRolls: {
      devScan: 20,
      withRush: 20,
      withCutSleeving: 20,
      withUncutSleeving: 20,
      withPrints: 20,
      with4KScans: 20,
      withProScans: 20
    },
    addRush: false,
    addCutSleeving: false,
    addUncutSleeving: false,
    addPrints: false,
    add4KScans: false,
    addProScans: false
  });

  function onChange(key, value) {
    if (typeof key === 'object') {
      setInputs({ ...inputs, ...key });
    } else {
      setInputs({ ...inputs, [key]: value });
    }
  }

  return (
    <Grid classes={{ root: classes.root }} ref={props.sectionRef} container>
      <Grid classes={{ root: classes.bg }}>
        <a href="#price-calculator" />
        <img
          className={classes.bgImg}
          src="https://storage.googleapis.com/nicefilmclub-public/PricingPage/pricing_calc_bg.jpeg"
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        classes={{ root: classes.calculator }}
      >
        <Grid container classes={{ root: classes.calculatorInner }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            classes={{ root: classes.header }}
          >
            <Grid container>
              <FlagSVG className={classes.headerFlag} />
            </Grid>

            <Typography
              classes={{ root: classes.headerTitle }}
              variant="h1"
              align="left"
              gutterBottom
            >
              Price Calculator
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <PriceCalculatorForm inputs={inputs} onChange={onChange} />
            </Grid>
            <Grid item xs={12} lg={8} style={{ overflow: 'scroll' }}>
              <PriceCalculatorOutput onChange={onChange} inputs={inputs} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PriceCalculator.propTypes = {
  sectionRef: PropTypes.object.isRequired
};
