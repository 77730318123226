import { chain, get, isEmpty, keyBy } from 'lodash';
import cx from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import dataURItoBlob from '../../lib/utils/data-url-to-blob';
import resize from '../../lib/utils/resize-image';
import { makeUserInitials } from '../../lib/utils/User/make-user-initials';
import useOutsideClick from '../../lib/utils/Hook/use-outside-click';
import { filterProductLines } from '../../lib/utils/Order/filter-product-lines';
import UserAPI from '../../lib/Api/User';

import makeStyles from '@mui/styles/makeStyles';
import arrayOfObjects from '../../lib/utils/PropType/array-of-objects';
import getPlan from '../../lib/utils/Customer/get-plan';
import CustomerMenuCredits from './CustomerMenuCredits';
import SelfServeLabelDispenser from './SelfServeLabelDispenser';
import AdminContext from '../../contexts/AdminContext';
import getPlanColor from '../../lib/utils/Customer/get-plan-color';
import moment from 'moment';

const useStyles = makeStyles(theme => {
  return {
    avatarButton: {
      border: props =>
        get(props.plan, 'id')
          ? `5px solid ${getPlanColor(props.plan, theme)}`
          : 'transparent',
      borderRadius: '50%',
      padding: '0px'
    },
    avatarInput: {
      display: 'none'
    },
    cartLink: {
      color: props =>
        props.color ? theme.palette[props.color].main : theme.palette.bg.main,
      cursor: 'pointer',
      fontSize: '11px',
      textTransform: 'uppercase',
      transition: 'all 250ms ease-in-out',
      width: '100px',
      '& > a': {
        '&:visited': {
          color: props =>
            props.color
              ? theme.palette[props.color].main
              : theme.palette.bg.main
        }
      },
      '&:hover': {
        color: theme.palette.secondary.main
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '10px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '8px'
      }
    },
    logo: {
      maxWidth: '120px'
    },
    menu: {
      background: theme.palette.bg.main,
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px',
      right: '-40px',
      position: 'absolute',
      top: '47px',
      width: '250px',
      [theme.breakpoints.down('md')]: {
        right: '-15px',
        top: '57px'
      }
    },
    menuLink: {
      fontSize: '13px',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:visited': {
        color: theme.palette.primary.main
      }
    },
    menuLinkLabel: {
      color: `${theme.palette.secondary.main}`,
      '&:visited': {
        color: theme.palette.secondary.main
      },
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline'
      }
    },
    menuList: {},
    menuListItem: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 10px'
    },
    name: {
      fontWeight: '200'
    },
    referral: {
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationStyle: 'dashed',
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      position: 'relative'
    },
    planColor: {
      color: props => getPlanColor(props.plan, theme)
    },
    pointer: {
      clip: 'rect(0px, 18px, 14px, -4px)',
      right: '8px',
      position: 'absolute',
      top: '34px',
      zIndex: '9999',
      [theme.breakpoints.down('md')]: {
        top: '44px'
      },
      '&::after': {
        boxShadow: 'rgba(0, 0, 0, 0.54) -1px -1px 1px -1px',
        content: '""',
        display: 'block',
        transform: 'rotate(45deg) translate(6px, 6px)',
        background: theme.palette.bg.main,
        height: '14px',
        width: '14px',
        zIndex: '9999'
      }
    },
    quickInfo: {
      alignItems: 'center',
      display: 'flex',
      flex: '1',
      fontSize: '10px',
      fontWeight: 'bold',
      justifyContent: 'space-between'
    },
    scheduleBtn: {
      background: 'transparent',
      backgroundImage: `linear-gradient(to bottom, transparent 50%, ${theme.palette.secondary.main} 50%)`,
      backgroundPosition: 'right top',
      backgroundSize: '200% 200%',
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      fontSize: '11px',
      marginRight: props => (!isEmpty(props.currentOrder) ? '0px' : '25px'),
      padding: '10px 5px',
      position: 'relative',
      transition: 'all 1s ease-in-out',
      width: '200px',
      '&:hover': {
        backgroundPosition: 'right bottom',
        backgroundColor: 'transparent',
        color: theme.palette.bg.main
      }
    },
    smallHorizontalPadding: {
      padding: '0px 10px'
    }
  };
});

export default function CustomerMenu(props) {
  const adminContext = useContext(AdminContext);
  const plan = getPlan(props.user);
  const classes = useStyles({
    ...props,
    plan
  });
  const imgUrl = get(props.user, 'profileImageUrl');
  const [avatar, setAvatar] = useState(imgUrl);
  const [openMenu, setOpenMenu] = useState(false);
  const [openRequestLabelForm, setOpenRequestLabelForm] = useState(false);
  const fileInput = useRef(null);
  const menuRef = useRef(null);

  useOutsideClick(menuRef, () => setOpenMenu(false));

  useEffect(() => {
    if (!props.user) return;

    setAvatar(props.user.profileImageUrl);
  }, [imgUrl]);

  function onUpload(e) {
    if (e) e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    reader.onload = ev =>
      resize(ev, dataURI => {
        const params = {
          fileType: file.type,
          fileName: file.name
        };
        const resizedFile = dataURItoBlob(dataURI);

        UserAPI.uploadProfilePic(props.user.id, params, resizedFile).then(
          ({ user }) => setAvatar(user.profileImageUrl)
        );
      });

    reader.readAsDataURL(file);
  }

  const cartProductsById = keyBy(props.cartProducts, 'id');
  const cartCount = chain(props.currentOrder)
    .get('lines')
    .thru(lines => filterProductLines(lines, cartProductsById))
    .sumBy('quantity')
    .value();

  const isRenewing = !props.user?.stripeCustomer?.subscriptions?.data[0]
    ?.cancel_at_period_end;
  const membershipCycleEndDate =
    props.user?.stripeCustomer?.subscriptions?.data[0]?.current_period_end;

  return (
    <div className={classes.root} ref={menuRef}>
      <SelfServeLabelDispenser
        getUser={props.getUser}
        open={openRequestLabelForm}
        onClose={() => setOpenRequestLabelForm(false)}
        user={props.user}
        history={props.history}
      />
      {!props.screenSize.isLessThan(768) && (
        <Button
          onClick={() => {
            props.resetCurrentDevOrder();
            props.history.push(`/users/${props.user.id}/orders/new/rolls`);
          }}
          variant="contained"
          color={props.color === 'primary' ? 'secondary' : 'primary'}
          size="small"
          className={classes.scheduleBtn}
        >
          New Order
        </Button>
      )}

      {!isEmpty(props.currentOrder) && (
        <Typography
          variant="h6"
          classes={{ root: classes.cartLink }}
          align="center"
          onClick={props.openCart}
        >
          Cart {cartCount > 0 && `(${cartCount})`}
        </Typography>
      )}

      <IconButton
        classes={{ root: classes.avatarButton }}
        onClick={() => setOpenMenu(!openMenu)}
        size="large"
      >
        <Avatar
          alt="Avatar"
          src={avatar}
          style={{
            backgroundColor: '#363636',
            fontSize: '13px',
            height: '32px',
            margin: '0px',
            width: '32px'
          }}
        >
          {makeUserInitials(props.user)}
        </Avatar>
      </IconButton>

      {openMenu && <div className={classes.pointer} />}

      {openMenu && (
        <div className={classes.menu}>
          <List classes={{ root: classes.menuList }}>
            <ListItem classes={{ root: classes.menuListItem }}>
              <label
                htmlFor="avatar-upload-input"
                onClick={() => fileInput.current.click()}
              >
                <IconButton
                  classes={{ root: classes.avatarButton }}
                  size="large"
                >
                  <Avatar
                    alt="Avatar"
                    src={avatar}
                    style={{
                      backgroundColor: '#363636',
                      margin: '0px',
                      width: '40px',
                      height: '40px'
                    }}
                  >
                    {makeUserInitials(props.user)}
                  </Avatar>
                </IconButton>
                <input
                  ref={fileInput}
                  accept="image/jpeg, image/png"
                  className={classes.avatarInput}
                  id="avatar-upload-input"
                  type="file"
                  onChange={onUpload}
                />
              </label>
              <div>
                <Typography
                  className={classes.name}
                  variant="body2"
                  align="right"
                >
                  {props.user.firstName}
                </Typography>
                <Typography
                  className={classes.name}
                  variant="body2"
                  align="right"
                >
                  {props.user.lastName}
                </Typography>
              </div>
            </ListItem>
            <CustomerMenuCredits
              credits={props.user.credits}
              user={props.user}
            />
            {!isEmpty(plan) && (
              <ListItem
                classes={{
                  root: cx(classes.menuListItem, classes.smallHorizontalPadding)
                }}
              >
                <div className={classes.quickInfo}>
                  <span>{isRenewing ? 'Renews On:' : 'Expires On:'}</span>
                  <u>
                    {moment.unix(membershipCycleEndDate).format('MM/DD/YY')}
                  </u>
                </div>
              </ListItem>
            )}
            {!isEmpty(plan) && (
              <ListItem
                classes={{
                  root: cx(classes.menuListItem, classes.smallHorizontalPadding)
                }}
              >
                <div className={classes.quickInfo}>
                  <span>Tier:</span>
                  <u className={classes.planColor}>{plan.label}</u>
                </div>
              </ListItem>
            )}

            <Divider className={classes.small} />

            <ListItem classes={{ root: classes.menuListItem }}>
              <Link
                className={classes.menuLink}
                to={`${adminContext.isAdmin ? '/admin' : ''}/users/${
                  props.user.id
                }/settings`}
              >
                Settings
              </Link>
            </ListItem>

            <Divider className={classes.small} />

            <ListItem classes={{ root: classes.menuListItem }}>
              <Link
                className={classes.menuLink}
                to={`/users/${props.user.id}/referrals`}
              >
                Referrals
              </Link>
              <span
                className={classes.referral}
                onClick={() =>
                  props.history.push(`/users/${props.user.id}/referrals`)
                }
              >
                {get(props.user, 'referralCode')}
              </span>
            </ListItem>

            {isEmpty(plan) && (
              <React.Fragment>
                <Divider className={classes.small} />
                <ListItem classes={{ root: classes.menuListItem }}>
                  <a
                    className={cx(classes.menuLink, classes.menuLinkLabel)}
                    onClick={() => props.history.push(`/memberships/purchase`)}
                  >
                    Become A Member
                  </a>
                </ListItem>
              </React.Fragment>
            )}

            <React.Fragment>
              <Divider className={classes.small} />
              <ListItem classes={{ root: classes.menuListItem }}>
                <a
                  onClick={() => setOpenRequestLabelForm(true)}
                  className={cx(classes.menuLink, classes.menuLinkLabel)}
                >
                  Request USPS Shipping Label
                </a>
              </ListItem>
            </React.Fragment>

            <Divider className={classes.small} />

            <ListItem classes={{ root: classes.menuListItem }}>
              <a
                className={classes.menuLink}
                href={`mailto:contact@nicefilmclub.com`}
              >
                Contact Us
              </a>
            </ListItem>

            <Divider className={classes.small} />

            <ListItem classes={{ root: classes.menuListItem }}>
              <span className={classes.menuLink} onClick={props.logout}>
                Logout
              </span>
            </ListItem>
          </List>
        </div>
      )}
    </div>
  );
}

CustomerMenu.propTypes = {
  cartProducts: arrayOfObjects,
  color: PropTypes.string,
  getUser: PropTypes.func,
  history: PropTypes.object.isRequired,
  logoLink: PropTypes.string,
  logout: PropTypes.func,
  resetCurrentDevOrder: PropTypes.func.isRequired,
  generalStats: PropTypes.object,
  step: PropTypes.number,
  screenSize: PropTypes.object.isRequired,
  openCart: PropTypes.func,
  currentOrder: PropTypes.object,
  user: PropTypes.object
};

CustomerMenu.defaultProps = {
  openCart: () => {},
  getUser: () => {}
};
