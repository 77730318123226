import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.bg.main,
    textTransform: 'capitalize',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  root: {},
  numRolls: {
    color: theme.palette.bg.main,
    textTransform: 'capitalize',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  numRollsInput: {
    color: theme.palette.bg.main,
    border: 'none',
    display: 'inline-block',
    fontSize: '16px',
    fontFamily: theme.typography.h6.fontFamily,
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    padding: '0px',
    width: 'auto',
    outline: 'none',
    backgroundColor: 'transparent',
    appearance: 'textfield', // Removes browser-specific styles for number inputs
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      padding: '0px'
    },
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      appearance: 'none', // Hides the increment/decrement buttons in Webkit browsers
      margin: 0
    },
    '&:disabled': {
      color: theme.palette.bg.main,
      opacity: 1,
      textDecoration: 'none',
      fontSize: '16px',
      minWidth: '12px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        minWidth: '9px'
      }
    }
  },
  hiddenSpan: {
    visibility: 'hidden',
    position: 'absolute',
    whiteSpace: 'pre',
    fontSize: 'inherit',
    fontFamily: theme.typography.h6.fontFamily
  }
}));

export default function PricingCalculatorAddOnCount(props) {
  const classes = useStyles();
  const [numRolls, setNumRolls] = useState(props.numRolls);
  const spanRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.numRolls !== numRolls) {
      setNumRolls(props.numRolls);
    }
  }, [props.numRolls]);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const calculatedWidth = `${spanRef.current.offsetWidth + 2}px`; // Add slight padding for visual comfort
      inputRef.current.style.width = calculatedWidth;
    }
  }, [numRolls, props.disabled, spanRef.current, inputRef.current]);

  const handleChange = event => {
    const value = +event.target.value;
    if (value > props.max) return;

    setNumRolls(value);
    props.onChange(value);
  };

  const handleKeyDown = event => {
    if (props.disabled || document.activeElement !== inputRef.current) return;

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (numRolls < props.max) {
        const newValue = numRolls + 1;
        setNumRolls(newValue);
        if (newValue !== numRolls) {
          props.onChange(newValue);
        }
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (numRolls > 0) {
        const newValue = numRolls - 1;
        setNumRolls(newValue);
        if (newValue !== numRolls) {
          props.onChange(newValue);
        }
      }
    }
  };

  return (
    <Grid classes={{ root: classes.root }} container>
      <Typography variant="h6" classes={{ root: classes.numRolls }}>
        {/* Hidden span for measuring the text width */}
        <span ref={spanRef} className={classes.hiddenSpan}>
          {numRolls}
        </span>
        <input
          ref={inputRef}
          type="number" // Numeric type for better mobile keyboard
          inputMode="numeric"
          className={classes.numRollsInput}
          value={numRolls}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={props.disabled}
          style={{
            width: inputRef.current ? inputRef.current.style.width : 'auto'
          }}
        />{' '}
        <span className={classes.label}>{props.label}</span>
      </Typography>
    </Grid>
  );
}

PricingCalculatorAddOnCount.propTypes = {
  disabled: PropTypes.bool.isRequired,
  numRolls: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
