import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import BenefitType from '../../lib/Constants/BenefitType';
import { capitalize, isNumber } from 'lodash';

const useStyles = makeStyles(theme => ({
  benefitName: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: '11px',
    fontWeight: '600',
    lineHeight: '16px',
    padding: '12px 0px 12px 48px',
    textTransform: 'uppercase',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '12px 0px 12px 24px'
    }
  },
  root: {
    alignItems: 'flex-start',
    background: props =>
      props.n % 2 === 0
        ? theme.palette.disableGray.main
        : theme.palette.bg.main,
    [theme.breakpoints.down('md')]: {
      minHeight: '56px'
    }
  },
  tierValue: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '600',
    justifyContent: 'center',
    padding: '12px 0px',
    textTransform: 'uppercase',
    width: '100%'
  }
}));

export default function BenefitRow(props) {
  const classes = useStyles(props);

  function makeValue(benefit) {
    if (benefit?.type === BenefitType.PRICE && isNumber(benefit?.value)) {
      return `${numeral(benefit?.value / 100).format('$0,0.00')}`;
    }
    if (isNumber(benefit?.value)) return benefit?.value;

    return benefit?.value
      .split(' ')
      .map(word => capitalize(word))
      .join(' ');
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid item xs={1.8} sm={2.4} classes={{ root: classes.benefitName }}>
        {props.benefit.name}
      </Grid>
      <Grid item xs={2.4} classes={{ root: classes.tierValue }}>
        {makeValue(props.benefit.nonMember)}
      </Grid>
      <Grid item xs={2.4} classes={{ root: classes.tierValue }}>
        {makeValue(props.benefit.nice)}
      </Grid>
      <Grid item xs={2.4} classes={{ root: classes.tierValue }}>
        {makeValue(props.benefit.veryNice)}
      </Grid>
      <Grid item xs={2.4} classes={{ root: classes.tierValue }}>
        {makeValue(props.benefit.superNice)}
      </Grid>
    </Grid>
  );
}

BenefitRow.propTypes = {
  n: PropTypes.number.isRequired,
  benefit: PropTypes.object.isRequired
};
