import Api from '../api';

const Auth = '/auth';

const AuthAPI = {
  getCurrentUser() {
    return Api.get(`${Auth}/current-user`);
  },

  facebookSignIn(token) {
    return Api.post(`${Auth}/facebook-signin`, { data: { token } });
  },

  googleSignIn(token) {
    return Api.post(`${Auth}/google-signin`, { data: { token } });
  },

  login(data) {
    return Api.post(`${Auth}/login`, { data });
  },

  signup(data) {
    return Api.post(`${Auth}/signup`, { data });
  },

  requestResetToken(email) {
    return Api.post(`${Auth}/password-reset`, { data: { email } });
  },

  resetPassword(password, token) {
    return Api.put(`${Auth}/password-reset/reset`, {
      data: { password, token }
    });
  },

  validateResetToken(token) {
    return Api.get(`${Auth}/password-reset/validate-token?token=${token}`);
  },

  requestManagerCode() {
    return Api.post(`${Auth}/managers/request-two-factor-code`);
  },

  verifyManagerCode(code) {
    return Api.post(`${Auth}/managers/verify-two-factor-code`, {
      data: { code }
    });
  }
};

export default AuthAPI;
