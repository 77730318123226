import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import numeral from 'numeral';
import { Divider, Typography } from '@mui/material';
import { sum } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CheckIcon } from '../../../assets/svg/confirmation.svg';
import Plan from '../../../lib/Constants/Plan';

const useStyles = makeStyles(theme => ({
  bestIcon: {
    height: '18px',
    marginLeft: '6px',
    marginTop: '2px',
    width: '18px',
    '& > g > g': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.bg.main
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0px'
    }
  },
  divider: {
    margin: '12px 0px',
    width: '100%'
  },
  dividerTotal: {
    background: theme.palette.bg.main,
    margin: '12px 0px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  price: {
    color: props => {
      if (props.tier.id === Plan.NON_MEMBER.id)
        return theme.palette.primary.main;
      return theme.palette.bg.main;
    },
    fontWeight: props => (props.isBestPlan ? 'bold' : 'normal'),
    fontSize: '16px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  priceSubtext: {
    color: theme.palette.bg.main,
    fontWeight: props => (props.isBestPlan ? 'bold' : 'normal'),
    fontSize: '8px',
    left: 0,
    position: 'absolute',
    top: '21px',
    [theme.breakpoints.down('md')]: {
      fontSize: '6px',
      top: '18px'
    }
  },
  root: {},
  totalPrice: {
    color: props => {
      if (!props.tier) return theme.palette.primary.main;
      return theme.palette.bg.main;
    },
    fontFamily: props =>
      props.tier
        ? theme.typography.h4.fontFamily
        : theme.typography.body1.fontFamily,
    fontSize: '18px',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  totalPricePerRoll: {
    color: theme.palette.bg.main,
    fontSize: '11px',
    [theme.breakpoints.down('md')]: {
      fontSize: '8px'
    }
  }
}));

// Pricing constants (all values are in cents)
const membershipPrices = {
  NON_MEMBER: 0,
  NICE_2025: 19999, // $299.99
  VERY_NICE_2025: 99999, // $999.99
  SUPER_NICE_2025: 499999 // $4999.99
};

const devScanPrices = {
  NON_MEMBER: 1799, // $17.99
  NICE_2025: 1499, // $11.99
  VERY_NICE_2025: 1199, // $11.99
  SUPER_NICE_2025: 999 // $9.99
};

const rollCredits = {
  NON_MEMBER: 0,
  NICE_2025: 10,
  VERY_NICE_2025: 30,
  SUPER_NICE_2025: 90
};

const scan4KPrices = {
  NON_MEMBER: 1199, // $11.99
  NICE_2025: 999, // $9.99
  VERY_NICE_2025: 299,
  SUPER_NICE_2025: 99
};

const scan4KCredits = {
  NON_MEMBER: 0,
  NICE_2025: 3, // 3 credits for Nice tier
  VERY_NICE_2025: 30, // Unlimited 4K scans for Very Nice
  SUPER_NICE_2025: 90 // Unlimited 4K scans for Super Nice
};

const rushPrices = {
  NON_MEMBER: 1499, // $14.99
  NICE_2025: 999, // $9.99
  VERY_NICE_2025: 499, // $4.99
  SUPER_NICE_2025: 0 // Included in the membership
};

const rushCredits = {
  NON_MEMBER: 0,
  NICE_2025: 3,
  VERY_NICE_2025: 30,
  SUPER_NICE_2025: Infinity // Rush included for free
};

const proScanPrices = {
  NON_MEMBER: 1999, // $24.99 per roll
  NICE_2025: 1499, // $19.99 per roll
  VERY_NICE_2025: 999, // $9.99 per roll
  SUPER_NICE_2025: 499 // $4.99 per roll
};

const cutSleevingPrices = {
  NON_MEMBER: 199, // $2.99
  NICE_2025: 199, // $1.99
  VERY_NICE_2025: 199, // $1.99
  SUPER_NICE_2025: 199 // $1.99
};

const uncutSleevingPrices = {
  NON_MEMBER: 99, // $2.99
  NICE_2025: 99, // $1.99
  VERY_NICE_2025: 99, // $1.99
  SUPER_NICE_2025: 0 // Included
};

const printsPrices = {
  NON_MEMBER: 1199, // $11.99 per roll
  OTHER_TIERS: 999 // $9.99 per roll
};

const proScanCredits = {
  NON_MEMBER: 0,
  NICE_2025: 0,
  VERY_NICE_2025: 3,
  SUPER_NICE_2025: 9
};

export default function PriceCalculatorOutputTier(props) {
  const classes = useStyles({ ...props, isBestPlan: props.isBestPlan });

  // If props.tier is null, default to NON_MEMBER pricing
  const tierId = props.tier.id;

  useEffect(() => {
    props.onChangeTotal(tierId, makeTotal());
  }, [props.inputs]);

  function makeMembershipPrice() {
    return membershipPrices[tierId];
  }

  function makeDevScanTotal(numRolls) {
    const rollCredit = rollCredits[tierId] || 0;
    const rollsToCharge = Math.max(0, numRolls - rollCredit);
    return rollsToCharge * (devScanPrices[tierId] || 0);
  }

  function make4KScanTotal(numRolls) {
    if (!props.inputs.add4KScans || props.inputs.addProScans) return 0;
    const scan4KCredit = scan4KCredits[tierId] || 0;
    const scansToCharge = Math.max(0, numRolls - scan4KCredit);
    return scansToCharge * (scan4KPrices[tierId] || 0);
  }

  function makeRushTotal(numRolls) {
    if (!props.inputs.addRush) return 0;
    const rushCredit = rushCredits[tierId] || 0;
    const rushesToCharge = Math.max(0, numRolls - rushCredit);
    return rushesToCharge * (rushPrices[tierId] || 0);
  }

  function makeProScanTotal(numRolls) {
    if (!props.inputs.addProScans) return 0;
    const proScanCredit = proScanCredits[tierId] || 0;
    const proScansToCharge = Math.max(0, numRolls - proScanCredit);
    return proScansToCharge * (proScanPrices[tierId] || 0);
  }

  function calculateSleevingTotal(numRolls, addSleeving, price) {
    return addSleeving ? numRolls * price : 0;
  }

  function makeSleevingTotal(numRolls) {
    const { addCutSleeving, addUncutSleeving } = props.inputs;

    const cutTotal = calculateSleevingTotal(
      numRolls,
      addCutSleeving,
      cutSleevingPrices[tierId]
    );
    const uncutTotal = calculateSleevingTotal(
      numRolls,
      addUncutSleeving,
      uncutSleevingPrices[tierId]
    );

    return cutTotal + uncutTotal;
  }

  function makePrintsTotal(numRolls) {
    if (!props.inputs.addPrints) return 0;
    const pricePerRoll =
      tierId === 'NON_MEMBER'
        ? printsPrices.NON_MEMBER
        : printsPrices.OTHER_TIERS;
    return numRolls * pricePerRoll;
  }

  function makeTotal() {
    return sum([
      makeMembershipPrice(),
      makeDevScanTotal(props.inputs.numRolls.devScan),
      make4KScanTotal(props.inputs.numRolls.with4KScans),
      makeRushTotal(props.inputs.numRolls.withRush),
      makeProScanTotal(props.inputs.numRolls.withProScans), // Add Pro Scans to the total
      makeSleevingTotal(
        props.inputs.numRolls.withUncutSleeving ||
          props.inputs.numRolls.withCutSleeving
      ),
      makePrintsTotal(props.inputs.numRolls.withPrints)
    ]);
  }

  function makeServiceSubtext(tierId, credits, pricePerRoll) {
    const formattedCredits = !credits
      ? ''
      : credits === Infinity
      ? 'Included /'
      : `${credits} credits /`;
    const formattedPrice = numeral(pricePerRoll / 100).format('$0,0.00');

    return (
      <span className={classes.priceSubtext}>
        {formattedCredits} {formattedPrice} per Roll
      </span>
    );
  }

  return (
    <Grid classes={{ root: classes.root }} item xs={2.4}>
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(makeMembershipPrice() / 100).format('$0,0.00')}
          {makeServiceSubtext(
            tierId,
            props.tier.rollCredits,
            devScanPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(
            makeDevScanTotal(props.inputs.numRolls.devScan) / 100
          ).format('$0,0.00')}
          {makeServiceSubtext(
            tierId,
            props.tier.rollCredits,
            devScanPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(makeRushTotal(props.inputs.numRolls.withRush) / 100).format(
            '$0,0.00'
          )}
          {makeServiceSubtext(
            tierId,
            props.tier.rushCredits,
            rushPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(
            make4KScanTotal(props.inputs.numRolls.with4KScans) / 100
          ).format('$0,0.00')}
          {makeServiceSubtext(
            tierId,
            props.tier.scan4KCredits,
            scan4KPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(
            makeProScanTotal(props.inputs.numRolls.withProScans) / 100
          ).format('$0,0.00')}
          {makeServiceSubtext(
            tierId,
            props.tier.proScanCredits,
            proScanPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(
            makeSleevingTotal(
              props.inputs.numRolls.withCutSleeving ||
                props.inputs.numRolls.withUncutSleeving
            ) / 100
          ).format('$0,0.00')}
          {makeServiceSubtext(
            tierId,
            null,
            props.inputs.addCutSleeving
              ? cutSleevingPrices[tierId]
              : uncutSleevingPrices[tierId]
          )}
        </Typography>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container flexDirection="column">
        <Typography variant="h6" classes={{ root: classes.price }}>
          {numeral(
            makePrintsTotal(props.inputs.numRolls.withPrints) / 100
          ).format('$0,0.00')}
          {makeServiceSubtext(tierId, null, printsPrices[tierId])}
        </Typography>
      </Grid>

      <Divider classes={{ root: classes.dividerTotal }} />

      <Grid container alignItems="flex-start">
        <Grid item xs="auto">
          <Typography variant="h4" classes={{ root: classes.totalPrice }}>
            {numeral(makeTotal() / 100).format('$0,0.00')}
          </Typography>
          <Typography
            variant="h6"
            classes={{ root: classes.totalPricePerRoll }}
          >
            {numeral(makeTotal() / 100 / props.inputs.numRolls.devScan).format(
              '$0,0.00'
            )}{' '}
            / ROLL
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {props.isBestPlan && <CheckIcon className={classes.bestIcon} />}
        </Grid>
      </Grid>
    </Grid>
  );
}

PriceCalculatorOutputTier.propTypes = {
  inputs: PropTypes.object.isRequired,
  tier: PropTypes.object.isRequired, // Can be null, which defaults to NON_MEMBER
  isBestPlan: PropTypes.bool,
  onChangeTotal: PropTypes.func.isRequired
};

PriceCalculatorOutputTier.defaultProps = {
  isBestPlan: false
};
