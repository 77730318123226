import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  bg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 0
  },
  bgImg: {
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    objectPosition: 'top center',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  },
  '@global': {
    '@keyframes glossyEffect': {
      '0%': {
        transform: 'translateX(-130%) skewX(-25deg)'
      },
      '100%': {
        transform: 'translateX(130%) skewX(-25deg)'
      }
    }
  },
  cta: {
    position: 'relative', // Establishes positioning context for the pseudo-element
    overflow: 'hidden', // Hides the pseudo-element when it's outside the button
    // Your existing button styles:
    background:
      'linear-gradient(135deg, #FBFBFA 0%, #DCDCDC 80%, #B0B0B0 100%)',
    borderRadius: '4px',
    boxShadow: '0px 20px 100px rgba(0, 0, 0, 0.1)',
    display: 'inline-block',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    lineHeight: '36px',
    letterSpacing: '0.16em',
    marginTop: '24px',
    padding: '0px 12px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
      fontSize: '8px'
    },
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '0px 30px 120px rgba(0, 0, 0, 0.2)'
      // Optionally, you can change the animation speed on hover too.
    },
    // The pseudo-element for the glossy shine:
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%', // The stripe covers 30% of the button's width—adjust as needed
      height: '100%',
      background:
        'linear-gradient(120deg, transparent 0%, rgba(255,255,255,0.5) 50%, transparent 100%)',
      transform: 'translateX(-130%) skewX(-25deg)', // Start off to the left
      animation: 'glossyEffect 2s infinite alternate ease-in-out'
      // "ease-in-out" gives a slight acceleration then deceleration effect.
      // "alternate" makes it reverse direction every cycle.
    }
  },
  descRoot: {
    alignItems: 'flex-start',
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  descRootInner: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '309px'
    }
  },
  desc: {
    color: theme.palette.bg.main,
    display: 'flex',
    lineHeight: '42px',
    marginTop: '102px',
    width: 'auto',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '36px',
      marginTop: '144px'
    }
  },
  root: {
    height: `${window.innerHeight}px`,
    width: `${window.innerWidth}px`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

export default function Hero(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid classes={{ root: classes.root }} container ref={props.sectionRef}>
      <Grid classes={{ root: classes.bg }}>
        <img
          className={classes.bgImg}
          src="https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/New%20Landing/hero.jpg"
        />
      </Grid>
      <Grid
        container
        classes={{ root: classes.descRoot }}
        flexDirection="column"
      >
        <Grid
          container
          classes={{ root: classes.descRootInner }}
          flexDirection="column"
        >
          {props.contentTitle && (
            <Typography variant="h1" classes={{ root: classes.desc }}>
              {props.contentTitle}
            </Typography>
          )}
          <Typography variant="h1" classes={{ root: classes.desc }}>
            Nice Film Club is a platform & <br /> full service film lab
            empowering
            <br />
            the film photography community.
          </Typography>
          <Grid item>
            <Button
              color="primary"
              classes={{ root: classes.cta }}
              onClick={() => history.push('/signup')}
            >
              Start Your First Order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Hero.propTypes = {
  sectionRef: PropTypes.object.isRequired,
  contentTitle: PropTypes.string
};
