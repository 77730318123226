import React from 'react';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import LightRoomIcon from '../MembershipOptions/LightRoomIcon';
import Plan from '../../lib/Constants/Plan';

const useStyles = makeStyles(theme => ({
  bar: {
    background: theme.palette.secondary.main,
    height: '6px',
    width: '100%'
  },
  barRoot: {
    marginTop: '12px'
  },
  root: {
    background: theme.palette.bg.main
  },
  tableHeaderDesc: {
    fontSize: '10px',
    fontStyle: 'italic',
    marginTop: '12px',
    minHeight: '50px',
    textAlign: 'center',
    padding: '0px 6px'
  },
  tableHeaderTitle: {
    fontSize: '28px',
    marginTop: '12px',
    cursor: 'pointer',
    '&:hover': {
      fontStyle: 'italic',
      textDecoration: 'underline'
    }
  }
}));

export default function TierHeaders(props) {
  const classes = useStyles(props);

  function onClickTier(tierId) {
    const baseURL = `${window.location.origin}/memberships/purchase?tier=${tierId}`;
    window.open(baseURL, '_blank');
  }

  return (
    <Grid container alignItems="flex-end" classes={{ root: classes.root }}>
      <Grid item xs={1.8} sm={2.4}></Grid>
      <Grid item xs={2.4}>
        <Grid container justifyContent="center">
          <Typography variant="h1" classes={{ root: classes.tableHeaderTitle }}>
            Non-Member
          </Typography>
          <Grid container classes={{ root: classes.tableHeaderDesc }} />
        </Grid>
      </Grid>
      <Grid item xs={2.4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <LightRoomIcon bg="secondary" color="bg" />
          <Typography
            variant="h1"
            classes={{ root: classes.tableHeaderTitle }}
            onClick={() => onClickTier(Plan.NICE_2025.id)}
          >
            Nice
          </Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.tableHeaderDesc }}
          >
            For Hobbyists and Pros shooting 20+ rolls a year looking for
            platform access and discounted services.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2.4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <LightRoomIcon bg="disableGray" color="info" />
          <Typography
            variant="h1"
            classes={{ root: classes.tableHeaderTitle }}
            onClick={() => onClickTier(Plan.VERY_NICE_2025.id)}
          >
            Very Nice
          </Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.tableHeaderDesc }}
          >
            For volume film shooters that want full access to TIFFs and further
            discounted Rush, 4K and Pro Scans.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2.4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <LightRoomIcon bg="accent" color="bg" />
          <Typography
            variant="h1"
            classes={{ root: classes.tableHeaderTitle }}
            onClick={() => onClickTier(Plan.SUPER_NICE_2025.id)}
          >
            Super Nice
          </Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.tableHeaderDesc }}
          >
            For professionals shooting 500+ rolls per year requiring Rush, TIFFs
            and heavily discounted 4K and Pro Scan services.
          </Typography>
        </Grid>
      </Grid>
      <Grid container classes={{ root: classes.barRoot }}>
        <Grid item xs={2.4} />
        <Grid item xs={2.4} />
        <Grid item xs={2.4} classes={{ root: classes.bar }} />
        <Grid item xs={2.4} classes={{ root: classes.bar }} />
        <Grid item xs={2.4} classes={{ root: classes.bar }} />
      </Grid>
    </Grid>
  );
}

TierHeaders.propTypes = {};
