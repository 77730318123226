import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import Typography from '@mui/material/Typography';
import { ReactComponent as NiceIcon } from '../../assets/svg/nice.svg';
import TierHeaders from './TierHeaders';
import Plan from '../../lib/Constants/Plan';
import BenefitRow from './BenefitRow';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import BenefitRowHeader from './BenefitRowHeader';

const useStyles = makeStyles(theme => ({
  '@global': {
    '@keyframes glossyEffect': {
      '0%': {
        transform: 'translateX(-130%) skewX(-25deg)'
      },
      '100%': {
        transform: 'translateX(130%) skewX(-25deg)'
      }
    }
  },
  cta: {
    color: theme.palette.bg.main,
    position: 'relative', // Establishes positioning context for the pseudo-element
    overflow: 'hidden', // Hides the pseudo-element when it's outside the button
    // Your existing button styles:
    background: `linear-gradient(135deg, #E96A3E 0%, #E9863E 100%)`,
    borderRadius: '4px',
    boxShadow: '0px 20px 100px rgba(0, 0, 0, 0.1)',
    display: 'inline-block',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    lineHeight: '30px',
    letterSpacing: '0.16em',
    marginTop: '12px',
    padding: '0px 12px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
      fontSize: '8px'
    },
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '0px 30px 120px rgba(0, 0, 0, 0.2)'
      // Optionally, you can change the animation speed on hover too.
    },
    // The pseudo-element for the glossy shine:
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%', // The stripe covers 30% of the button's width—adjust as needed
      height: '100%',
      background:
        'linear-gradient(120deg, transparent 0%, rgba(255,255,255,0.5) 50%, transparent 100%)',
      transform: 'translateX(-130%) skewX(-25deg)', // Start off to the left
      animation: 'glossyEffect 2s infinite alternate ease-in-out'
      // "ease-in-out" gives a slight acceleration then deceleration effect.
      // "alternate" makes it reverse direction every cycle.
    }
  },
  ctaWrapper: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  header: {
    alignItems: 'flex-start',
    padding: '72px 48px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 24px'
    }
  },
  headerSubtitle: {
    fontSize: '32px',
    fontStyle: 'italic',
    lineHeight: '32px',
    textAlign: 'right',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  },
  headerNice: {
    height: '40px',
    cursor: 'pointer',
    '& > g > path': {
      fill: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      height: '16px',
      width: '58.63px'
    }
  },
  benefits: {
    margin: '0px 0px 96px 0px',
    display: 'block',
    minWidth: '960px',
    overflowX: 'auto',
    overflowY: 'auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      minWidth: '960px'
    }
  },
  benefitsHeaderSticky: {
    height: '128px',
    top: 0,
    minWidth: '960px',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    transition: 'top 0.3s ease, transform 0.3s ease',
    [theme.breakpoints.down('md')]: {
      minWidth: '960px'
    }
  },
  benefitsHeaderFixed: {
    backgroundColor: theme.palette.bg.main,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    left: 0,
    minWidth: '960px',
    paddingTop: '18px',
    position: 'fixed',
    top: props => (props.parentScrollRef?.current ? 60 : 100),
    transition: 'top 0.3s ease, transform 0.3s ease',
    width: '100%',
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      minWidth: '960px'
    }
  },
  benefitsTables: {
    marginTop: '162px'
  },
  root: {
    background: theme.palette.bg.main,
    overflow: 'scroll'
  }
}));

export default function Memberships(props) {
  const benefitsRef = useRef(null);
  const headerRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const classes = useStyles(props);

  useEffect(() => {
    const handleScroll = () => {
      const container = benefitsRef.current;
      const header = headerRef.current;

      if (!container || !header) return;

      const containerRect = container.getBoundingClientRect();
      const headerHeight = header.offsetHeight;

      if (
        containerRect.top <= 127 &&
        containerRect.bottom - headerHeight > 0 &&
        !props.screenSize.isSmallScreen
      ) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Use parentScrollRef if available, fallback to window
    const scrollContainer = props.parentScrollRef?.current || window;

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [props.parentScrollRef]);

  useEffect(() => {
    if (!props.sectionRef || !props.sectionRef.current) return;

    const offsetTop = get(props.sectionRef, 'current.offsetTop', 0);
    const diff = props.scrollTop + props.screenSize.height / 2 - offsetTop;
    const intersected = diff > 0;

    if (props.intersected === intersected) return;

    props.setIntersected(intersected);
  }, [props.scrollTop, props.sectionRef]);

  const serviceBenefits = [
    {
      name: 'Annual Cost',
      nonMember: Plan.NON_MEMBER.benefits.annualCost,
      nice: Plan.NICE_2025.benefits.annualCost,
      veryNice: Plan.VERY_NICE_2025.benefits.annualCost,
      superNice: Plan.SUPER_NICE_2025.benefits.annualCost
    },
    {
      name: 'Dev & Scan Credits',
      nonMember: Plan.NON_MEMBER.benefits.devScanCredits,
      nice: Plan.NICE_2025.benefits.devScanCredits,
      veryNice: Plan.VERY_NICE_2025.benefits.devScanCredits,
      superNice: Plan.SUPER_NICE_2025.benefits.devScanCredits
    },
    {
      name: 'Rush Credits',
      nonMember: Plan.NON_MEMBER.benefits.rushCredits,
      nice: Plan.NICE_2025.benefits.rushCredits,
      veryNice: Plan.VERY_NICE_2025.benefits.rushCredits,
      superNice: Plan.SUPER_NICE_2025.benefits.rushCredits
    },
    {
      name: '4K Scan Credits',
      nonMember: Plan.NON_MEMBER.benefits.scan4KCredits,
      nice: Plan.NICE_2025.benefits.scan4KCredits,
      veryNice: Plan.VERY_NICE_2025.benefits.scan4KCredits,
      superNice: Plan.SUPER_NICE_2025.benefits.scan4KCredits
    },
    {
      name: 'Pro Scan Credits',
      nonMember: Plan.NON_MEMBER.benefits.proScanCredits,
      nice: Plan.NICE_2025.benefits.proScanCredits,
      veryNice: Plan.VERY_NICE_2025.benefits.proScanCredits,
      superNice: Plan.SUPER_NICE_2025.benefits.proScanCredits
    },
    {
      name: 'Turnaround',
      nonMember: Plan.NON_MEMBER.benefits.turnaround,
      nice: Plan.NICE_2025.benefits.turnaround,
      veryNice: Plan.VERY_NICE_2025.benefits.turnaround,
      superNice: Plan.SUPER_NICE_2025.benefits.turnaround
    },
    {
      name: 'TIFF Files',
      nonMember: Plan.NON_MEMBER.benefits.tiffs,
      nice: Plan.NICE_2025.benefits.tiffs,
      veryNice: Plan.VERY_NICE_2025.benefits.tiffs,
      superNice: Plan.SUPER_NICE_2025.benefits.tiffs
    },
    {
      name: 'Platform Access',
      nonMember: Plan.NON_MEMBER.benefits.platformAccess,
      nice: Plan.NICE_2025.benefits.platformAccess,
      veryNice: Plan.VERY_NICE_2025.benefits.platformAccess,
      superNice: Plan.SUPER_NICE_2025.benefits.platformAccess
    },
    {
      name: 'Digital Contact Sheets',
      nonMember: Plan.NON_MEMBER.benefits.digitalContactSheets,
      nice: Plan.NICE_2025.benefits.digitalContactSheets,
      veryNice: Plan.VERY_NICE_2025.benefits.digitalContactSheets,
      superNice: Plan.SUPER_NICE_2025.benefits.digitalContactSheets
    },
    {
      name: 'Scan Delivery',
      nonMember: Plan.NON_MEMBER.benefits.scanDelivery,
      nice: Plan.NICE_2025.benefits.scanDelivery,
      veryNice: Plan.VERY_NICE_2025.benefits.scanDelivery,
      superNice: Plan.SUPER_NICE_2025.benefits.scanDelivery
    },
    {
      name: 'Scan Storage',
      nonMember: Plan.NON_MEMBER.benefits.scanStorage,
      nice: Plan.NICE_2025.benefits.scanStorage,
      veryNice: Plan.VERY_NICE_2025.benefits.scanStorage,
      superNice: Plan.SUPER_NICE_2025.benefits.scanStorage
    },
    {
      name: 'USPS Labels To The Lab',
      nonMember: Plan.NON_MEMBER.benefits.uspsLabels,
      nice: Plan.NICE_2025.benefits.uspsLabels,
      veryNice: Plan.VERY_NICE_2025.benefits.uspsLabels,
      superNice: Plan.SUPER_NICE_2025.benefits.uspsLabels
    }
  ];

  const pricing = [
    {
      name: 'C-41 Develop + Scan (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.devScanPrice,
      nice: Plan.NICE_2025.benefits.devScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.devScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.devScanPrice
    },
    {
      name: 'BW Develop + Scan (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.devScanPrice,
      nice: Plan.NICE_2025.benefits.devScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.devScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.devScanPrice
    },
    {
      name: 'Disposable Camera Develop + Scan (Per Camera)',
      nonMember: Plan.NON_MEMBER.benefits.devScanPrice,
      nice: Plan.NICE_2025.benefits.devScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.devScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.devScanPrice
    },
    {
      name: 'E-6 Develop + Scan (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.e6DevScanPrice,
      nice: Plan.NICE_2025.benefits.e6DevScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.e6DevScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.e6DevScanPrice
    },
    {
      name: 'ECN-2 Develop + Scan (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.ecn2DevScanPrice,
      nice: Plan.NICE_2025.benefits.ecn2DevScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.ecn2DevScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.ecn2DevScanPrice
    }
  ];

  const addOns = [
    {
      name: '4K Scans (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.scan4KPrice,
      nice: Plan.NICE_2025.benefits.scan4KPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.scan4KPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.scan4KPrice
    },
    {
      name: 'Pro Scans (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.proScanPrice,
      nice: Plan.NICE_2025.benefits.proScanPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.proScanPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.proScanPrice
    },
    {
      name: 'Rush Scans (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.rushPrice,
      nice: Plan.NICE_2025.benefits.rushPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.rushPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.rushPrice
    },
    {
      name: 'Cut Sleeving (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.cutSleevingPrice,
      nice: Plan.NICE_2025.benefits.cutSleevingPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.cutSleevingPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.cutSleevingPrice
    },
    {
      name: 'Uncut Sleeving (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.uncutSleevingPrice,
      nice: Plan.NICE_2025.benefits.uncutSleevingPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.uncutSleevingPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.uncutSleevingPrice
    },
    {
      name: 'Push / Pull (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.pushPullPrice,
      nice: Plan.NICE_2025.benefits.pushPullPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.pushPullPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.pushPullPrice
    },
    {
      name: 'Half Frames (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.halfFramesPrice,
      nice: Plan.NICE_2025.benefits.halfFramesPrice,
      veryNice: Plan.VERY_NICE_2025.benefits.halfFramesPrice,
      superNice: Plan.SUPER_NICE_2025.benefits.halfFramesPrice
    },
    {
      name: '4 x 6 Prints (Per Roll)',
      nonMember: Plan.NON_MEMBER.benefits.prints4X6Price,
      nice: Plan.NICE_2025.benefits.prints4X6Price,
      veryNice: Plan.VERY_NICE_2025.benefits.prints4X6Price,
      superNice: Plan.SUPER_NICE_2025.benefits.prints4X6Price
    },
    {
      name: 'Return Shipping (Per Order)',
      nonMember: Plan.NON_MEMBER.benefits.returnShipping,
      nice: Plan.NICE_2025.benefits.returnShipping,
      veryNice: Plan.VERY_NICE_2025.benefits.returnShipping,
      superNice: Plan.SUPER_NICE_2025.benefits.returnShipping
    }
  ];

  const renderFixedHeader = () => (
    <div ref={headerRef} className={`${classes.benefitsHeaderFixed}`}>
      <TierHeaders />
    </div>
  );

  const renderStickyHeader = () => (
    <div ref={headerRef} className={`${classes.benefitsHeaderSticky}`}>
      <TierHeaders />
    </div>
  );

  return (
    <>
      {isFixed && ReactDOM.createPortal(renderFixedHeader(), document.body)}
      <Grid classes={{ root: classes.root }} container ref={props.sectionRef}>
        <a href="#memberships" />
        <Grid
          container
          classes={{ root: classes.header }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item sm={6} xs={12}>
            <NiceIcon
              onClick={() => props.history.push('/')}
              className={classes.headerNice}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h1" classes={{ root: classes.headerSubtitle }}>
              Membership Tiers & Pricing
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Grid container classes={{ root: classes.ctaWrapper }}>
              <Button
                color="primary"
                classes={{ root: classes.cta }}
                onClick={() => props.history.push('/signup')}
              >
                Start Your First Order
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          ref={benefitsRef}
          container
          classes={{ root: classes.benefits }}
          flexDirection="column"
        >
          {!isFixed && renderStickyHeader()}

          <Grid container classes={{ root: classes.benefitsTables }}>
            <BenefitRowHeader title={'Benefits'} hideTiers />
            {serviceBenefits.map((benefit, i) => (
              <BenefitRow key={i} n={i} benefit={benefit} />
            ))}
            <BenefitRowHeader title={'Pricing'} />
            {pricing.map((benefit, i) => (
              <BenefitRow key={i} n={i} benefit={benefit} />
            ))}
            <BenefitRowHeader title={'Add-Ons'} />
            {addOns.map((benefit, i) => (
              <BenefitRow key={i} n={i} benefit={benefit} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Memberships.propTypes = {
  screenSize: PropTypes.object,
  scrollTop: PropTypes.number,
  history: PropTypes.object,
  intersected: PropTypes.bool,
  setIntersected: PropTypes.func,
  sectionRef: PropTypes.object,
  parentScrollRef: PropTypes.object,
  isLoggedIn: PropTypes.bool
};

Memberships.defaultProps = {
  intersected: false,
  scrollTop: 0,
  sectionRef: null,
  isLoggedIn: false,
  setIntersected: () => {}
};
