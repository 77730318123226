import Plan from './Plan';
import AddonSKU from './AddonSKU';
import FulfillmentSKU from './FulfillmentSKU'; // Assuming Plan is being imported from your constants file

export default {
  [AddonSKU.RUSH]: {
    [Plan.NON_MEMBER.id]: 1499,
    [Plan.NICE_2025.id]: 999,
    [Plan.VERY_NICE_2025.id]: 499,
    [Plan.SUPER_NICE_2025.id]: 0, // Free for Super Nice members
    [Plan.NICE_2023.id]: 999,
    [Plan.NICE_2021.id]: 999,
    [Plan.NICE_MONTHLY.id]: 999,
    [Plan.VERY_NICE_2023.id]: 999,
    [Plan.SUPER_NICE_2023.id]: 0
  },
  [AddonSKU.PRO_SCANS]: {
    [Plan.NON_MEMBER.id]: 1999,
    [Plan.NICE_2025.id]: 1499,
    [Plan.VERY_NICE_2025.id]: 999,
    [Plan.SUPER_NICE_2025.id]: 499,
    [Plan.NICE_2023.id]: 1999,
    [Plan.NICE_2021.id]: 1999,
    [Plan.NICE_MONTHLY.id]: 1999,
    [Plan.VERY_NICE_2023.id]: 1999,
    [Plan.SUPER_NICE_2023.id]: 1999
  },
  [AddonSKU.HALF_FRAMES]: {
    [Plan.NON_MEMBER.id]: 999,
    [Plan.NICE_2025.id]: 799,
    [Plan.VERY_NICE_2025.id]: 799,
    [Plan.SUPER_NICE_2025.id]: 799,
    [Plan.NICE_2023.id]: 599,
    [Plan.NICE_2021.id]: 599,
    [Plan.NICE_MONTHLY.id]: 599,
    [Plan.VERY_NICE_2023.id]: 599,
    [Plan.SUPER_NICE_2023.id]: 599
  },
  [AddonSKU.E_6]: {
    [Plan.NON_MEMBER.id]: 500,
    [Plan.NICE_2025.id]: 500,
    [Plan.VERY_NICE_2025.id]: 500,
    [Plan.SUPER_NICE_2025.id]: 500,
    [Plan.NICE_2023.id]: 500,
    [Plan.NICE_2021.id]: 500,
    [Plan.NICE_MONTHLY.id]: 500,
    [Plan.VERY_NICE_2023.id]: 500,
    [Plan.SUPER_NICE_2023.id]: 500
  },
  [AddonSKU.ECN_2]: {
    [Plan.NON_MEMBER.id]: 1500,
    [Plan.NICE_2025.id]: 1500,
    [Plan.VERY_NICE_2025.id]: 1500,
    [Plan.SUPER_NICE_2025.id]: 1500,
    [Plan.NICE_2023.id]: 1500,
    [Plan.NICE_2021.id]: 1500,
    [Plan.NICE_MONTHLY.id]: 1500,
    [Plan.VERY_NICE_2023.id]: 1500,
    [Plan.SUPER_NICE_2023.id]: 1500
  },
  [AddonSKU.PRINTS_4X6]: {
    [Plan.NON_MEMBER.id]: 1199,
    [Plan.NICE_2025.id]: 999,
    [Plan.VERY_NICE_2025.id]: 999,
    [Plan.SUPER_NICE_2025.id]: 999,
    [Plan.NICE_2023.id]: 999,
    [Plan.NICE_2021.id]: 999,
    [Plan.NICE_MONTHLY.id]: 999,
    [Plan.VERY_NICE_2023.id]: 999,
    [Plan.SUPER_NICE_2023.id]: 999
  },
  [AddonSKU.SCANS_4K]: {
    [Plan.NON_MEMBER.id]: 1199,
    [Plan.NICE_2025.id]: 999,
    [Plan.VERY_NICE_2025.id]: 299,
    [Plan.SUPER_NICE_2025.id]: 99,
    [Plan.NICE_2023.id]: 0, // Included for legacy Nice members
    [Plan.NICE_2021.id]: 0,
    [Plan.NICE_MONTHLY.id]: 0,
    [Plan.VERY_NICE_2023.id]: 0, // Included for legacy Very Nice members
    [Plan.SUPER_NICE_2023.id]: 0 // Included for legacy Super Nice members
  },
  [AddonSKU.CUT_SLEEVING]: {
    [Plan.NON_MEMBER.id]: 199,
    [Plan.NICE_2025.id]: 199,
    [Plan.VERY_NICE_2025.id]: 199,
    [Plan.SUPER_NICE_2025.id]: 199,
    [Plan.NICE_2023.id]: 199,
    [Plan.NICE_2021.id]: 199,
    [Plan.NICE_MONTHLY.id]: 199,
    [Plan.VERY_NICE_2023.id]: 199,
    [Plan.SUPER_NICE_2023.id]: 199
  },
  [AddonSKU.UNCUT_SLEEVING]: {
    [Plan.NON_MEMBER.id]: 199,
    [Plan.NICE_2025.id]: 99,
    [Plan.VERY_NICE_2025.id]: 99,
    [Plan.SUPER_NICE_2025.id]: 0, // Free for Super Nice members
    [Plan.NICE_2023.id]: 199,
    [Plan.NICE_2021.id]: 199,
    [Plan.NICE_MONTHLY.id]: 199,
    [Plan.VERY_NICE_2023.id]: 199,
    [Plan.SUPER_NICE_2023.id]: 199
  },
  [AddonSKU.PUSH_PULL_ROLL]: {
    [Plan.NON_MEMBER.id]: 299,
    [Plan.NICE_2025.id]: 199,
    [Plan.VERY_NICE_2025.id]: 199,
    [Plan.SUPER_NICE_2025.id]: 199,
    [Plan.NICE_2023.id]: 199,
    [Plan.NICE_2021.id]: 199,
    [Plan.NICE_MONTHLY.id]: 199,
    [Plan.VERY_NICE_2023.id]: 199,
    [Plan.SUPER_NICE_2023.id]: 199
  },
  [AddonSKU.ADDITIONAL_ROLL]: {
    [Plan.NON_MEMBER.id]: 1799,
    [Plan.NICE_2025.id]: 1499,
    [Plan.VERY_NICE_2025.id]: 1199,
    [Plan.SUPER_NICE_2025.id]: 999,
    [Plan.NICE_2023.id]: 999,
    [Plan.NICE_2021.id]: 999,
    [Plan.NICE_MONTHLY.id]: 999,
    [Plan.VERY_NICE_2023.id]: 999,
    [Plan.SUPER_NICE_2023.id]: 999
  },
  [FulfillmentSKU.RETURN_NEGATIVES]: {
    [Plan.NON_MEMBER.id]: 799,
    [Plan.NICE_2025.id]: 699,
    [Plan.VERY_NICE_2025.id]: 0,
    [Plan.SUPER_NICE_2025.id]: 0,
    [Plan.NICE_2023.id]: 999,
    [Plan.NICE_2021.id]: 999,
    [Plan.NICE_MONTHLY.id]: 999,
    [Plan.VERY_NICE_2023.id]: 499,
    [Plan.SUPER_NICE_2023.id]: 0
  }
};
