import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { Divider, Typography } from '@mui/material';
import PriceCalculatorOutputRows from './PriceCalculatorOutputRows';
import LightRoomIcon from '../../../components/MembershipOptions/LightRoomIcon';

const useStyles = makeStyles(theme => ({
  divider: {
    background: theme.palette.bg.main,
    margin: '12px 0px',
    width: '100%'
  },
  root: {
    [theme.breakpoints.down('md')]: {
      minWidth: '720px'
    }
  },
  title: {
    color: theme.palette.info.main,
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.bg.main,
      fontSize: '10px'
    }
  },
  titleNice: {
    color: theme.palette.bg.main
  },
  titleVeryNice: {
    color: theme.palette.bg.main
  },
  titleSuperNice: {
    color: theme.palette.bg.main
  },
  titleOnDemand: {
    color: theme.palette.primary.main
  }
}));

export default function PriceCalculatorOutput(props) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid container classes={{ root: classes.header }} alignItems="center">
        <Grid item xs={2.4}>
          <Typography variant="h5" classes={{ root: classes.title }}>
            Service
          </Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography
            variant="h5"
            classes={{ root: cx(classes.title, classes.titleOnDemand) }}
          >
            On Demand
          </Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography
            variant="h5"
            classes={{
              root: cx(classes.title, classes.titleNice)
            }}
          >
            Nice <br /> Membership
          </Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Grid container>
            <Typography
              variant="h5"
              classes={{
                root: cx(classes.title, classes.titleVeryNice)
              }}
            >
              Very Nice <br /> Membership
            </Typography>
            <LightRoomIcon bg={'secondary'} color={'bg'} size={18} />
          </Grid>
        </Grid>
        <Grid item xs={2.4}>
          <Grid container>
            <Typography
              variant="h5"
              classes={{
                root: cx(classes.title, classes.titleSuperNice)
              }}
            >
              Super Nice <br /> Membership
            </Typography>
            <LightRoomIcon bg={'accent'} color={'bg'} size={18} />
          </Grid>
        </Grid>
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid container classes={{ root: classes.outputTable }}>
        <PriceCalculatorOutputRows
          onChange={props.onChange}
          inputs={props.inputs}
        />
      </Grid>
    </Grid>
  );
}

PriceCalculatorOutput.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
