import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { Divider, Typography } from '@mui/material';
import PriceCalculatorOutputTier from './PriceCalculatorOutputTier';
import Plan from '../../../lib/Constants/Plan';
import PricingCalculatorAddOnCount from './PricingCalculatorAddOnCount';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: '12px 0px',
    width: '100%'
  },
  dividerTotal: {
    background: theme.palette.bg.main,
    margin: '12px 0px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  memberPrice: {
    color: theme.palette.bg.main,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  memberPriceAccent: {
    fontSize: '12px',
    fontStyle: 'italic',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  root: {},
  service: {
    color: theme.palette.bg.main,
    textTransform: 'capitalize',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  total: {
    color: theme.palette.bg.main,
    fontSize: '16px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  totalMemberPrice: {
    color: theme.palette.bg.main,
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}));

export default function PriceCalculatorOutputRows(props) {
  const classes = useStyles();
  const [planTotals, setPlanTotals] = useState({
    NON_MEMBER: 0,
    [Plan.NICE_2025.id]: 0,
    [Plan.VERY_NICE_2025.id]: 0,
    [Plan.SUPER_NICE_2025.id]: 0
  });

  function onChangeTotal(planId, total) {
    setPlanTotals(planTotals => ({
      ...planTotals,
      [planId]: total
    }));
  }

  function getBestPlan(planTotals) {
    return Object.keys(planTotals).reduce((a, b) => {
      return planTotals[a] < planTotals[b] ? a : b;
    });
  }

  const bestPlan = getBestPlan(planTotals);

  return (
    <Grid classes={{ root: classes.root }} container direction="row">
      <Grid item xs={2.4}>
        <Grid container>
          <Typography variant="h6" classes={{ root: classes.service }}>
            Membership
          </Typography>
        </Grid>
        <Divider classes={{ root: classes.divider }} />
        <Grid container>
          <Typography variant="h6" classes={{ root: classes.service }}>
            {props.inputs.numRolls.devScan} Dev + Scan
          </Typography>
        </Grid>
        <Divider classes={{ root: classes.divider }} />
        <PricingCalculatorAddOnCount
          numRolls={props.inputs.addRush ? props.inputs.numRolls.withRush : 0}
          label={'Rush'}
          max={props.inputs.numRolls.devScan}
          disabled={!props.inputs.addRush}
          onChange={numRush =>
            props.onChange({
              numRolls: { ...props.inputs.numRolls, withRush: numRush }
            })
          }
        />
        <Divider classes={{ root: classes.divider }} />
        <PricingCalculatorAddOnCount
          numRolls={
            props.inputs.add4KScans ? props.inputs.numRolls.with4KScans : 0
          }
          disabled={!props.inputs.add4KScans}
          label="4K Scans"
          max={props.inputs.numRolls.devScan}
          onChange={num4KScans =>
            props.onChange({
              numRolls: {
                ...props.inputs.numRolls,
                with4KScans: num4KScans
              }
            })
          }
        />
        <Divider classes={{ root: classes.divider }} />
        <PricingCalculatorAddOnCount
          numRolls={
            props.inputs.addProScans ? props.inputs.numRolls.withProScans : 0
          }
          disabled={!props.inputs.addProScans}
          label={'Pro Scans'}
          max={props.inputs.numRolls.devScan}
          onChange={numProScans =>
            props.onChange({
              numRolls: {
                ...props.inputs.numRolls,
                withProScans: numProScans
              }
            })
          }
        />
        <Divider classes={{ root: classes.divider }} />
        <PricingCalculatorAddOnCount
          disabled={
            !props.inputs.addCutSleeving && !props.inputs.addUncutSleeving
          }
          numRolls={
            !props.inputs.addCutSleeving && !props.inputs.addUncutSleeving
              ? 0
              : props.inputs.addCutSleeving
              ? props.inputs.numRolls.withCutSleeving
              : props.inputs.numRolls.withUncutSleeving
          }
          label={'Sleeving'}
          max={props.inputs.numRolls.devScan}
          onChange={numRolls =>
            props.onChange({
              numRolls: {
                ...props.inputs.numRolls,
                withCutSleeving: props.inputs.addCutSleeving ? numRolls : 0,
                withUncutSleeving: props.inputs.addUncutSleeving ? numRolls : 0
              }
            })
          }
        />
        <Divider classes={{ root: classes.divider }} />

        <PricingCalculatorAddOnCount
          numRolls={
            props.inputs.addPrints ? props.inputs.numRolls.withPrints : 0
          }
          disabled={!props.inputs.addPrints}
          label={'Prints'}
          max={props.inputs.numRolls.devScan}
          onChange={numPrints =>
            props.onChange({
              numRolls: { ...props.inputs.numRolls, withPrints: numPrints }
            })
          }
        />

        <Divider classes={{ root: classes.dividerTotal }} />
        <Grid container>
          <Typography variant="h4" classes={{ root: classes.total }}>
            TOTAL
          </Typography>
        </Grid>
      </Grid>

      <PriceCalculatorOutputTier
        inputs={props.inputs}
        tier={{ id: 'NON_MEMBER' }}
        isBestPlan={false}
        onChangeTotal={onChangeTotal}
      />
      <PriceCalculatorOutputTier
        inputs={props.inputs}
        tier={Plan.NICE_2025}
        isBestPlan={bestPlan === Plan.NICE_2025.id}
        onChangeTotal={onChangeTotal}
      />
      <PriceCalculatorOutputTier
        inputs={props.inputs}
        tier={Plan.VERY_NICE_2025}
        isBestPlan={bestPlan === Plan.VERY_NICE_2025.id}
        onChangeTotal={onChangeTotal}
      />
      <PriceCalculatorOutputTier
        inputs={props.inputs}
        tier={Plan.SUPER_NICE_2025}
        isBestPlan={bestPlan === Plan.SUPER_NICE_2025.id}
        onChangeTotal={onChangeTotal}
      />
    </Grid>
  );
}

PriceCalculatorOutputRows.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
