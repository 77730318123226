import cx from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CheckCircle } from '../../../assets/svg/checkcircle.svg';
import { ReactComponent as CheckCircleChecked } from '../../../assets/svg/checkcirclechecked.svg';
import { Checkbox, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  addOn: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '12px',
    paddingRight: '18px'
  },
  addOnCheckbox: {
    background: 'rgba(156, 146, 136, 0.24)'
  },
  addOnLabel: {
    color: theme.palette.bg.main,
    fontSize: '16px',
    marginLeft: '18px'
  },
  checked: {
    width: '16px',
    height: '16px'
  },
  label: {
    color: theme.palette.bg.main,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.04em',
    margin: '18px 0px',
    textTransform: 'uppercase'
  },
  mobileHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  root: {}
}));

export default function AddOns(props) {
  const classes = useStyles();
  const [addRush, setAddRush] = useState(false);
  const [addCutSleeving, setAddCutSleeving] = useState(false);
  const [addUncutSleeving, setAddUncutSleeving] = useState(false);
  const [addPrints, setAddPrints] = useState(false);
  const [add4KScans, setAdd4KScans] = useState(false);
  const [addProScans, setAddProScans] = useState(false);

  return (
    <Grid classes={{ root: classes.root }} container>
      <Typography variant="h4" classes={{ root: classes.label }}>
        With Service Add-Ons
      </Typography>
      <Grid container>
        <Grid classes={{ root: classes.addOn }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={addCutSleeving}
            onChange={() => {
              props.onChange({
                addCutSleeving: !addCutSleeving,
                addUncutSleeving: false
              });
              setAddCutSleeving(!addCutSleeving);
              setAddUncutSleeving(false);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            Cut Sleeving
          </Typography>
        </Grid>
        <Grid classes={{ root: cx(classes.addOn, classes.mobileHide) }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={addUncutSleeving}
            onChange={() => {
              props.onChange({
                addUncutSleeving: !addUncutSleeving,
                addCutSleeving: false
              });
              setAddUncutSleeving(!addUncutSleeving);
              setAddCutSleeving(false);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            Uncut Sleeving
          </Typography>
        </Grid>
        <Grid classes={{ root: classes.addOn }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={addRush}
            onChange={() => {
              props.onChange('addRush', !addRush);
              setAddRush(!addRush);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            Rush
          </Typography>
        </Grid>
        <Grid classes={{ root: classes.addOn }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={addPrints}
            onChange={() => {
              props.onChange('addPrints', !addPrints);
              setAddPrints(!addPrints);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            Prints
          </Typography>
        </Grid>
        <Grid classes={{ root: classes.addOn }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={add4KScans}
            onChange={() => {
              props.onChange({
                add4KScans: !add4KScans,
                addProScans: false
              });
              setAdd4KScans(!add4KScans);
              setAddProScans(false);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            4K Scans
          </Typography>
        </Grid>
        <Grid classes={{ root: classes.addOn }} item>
          <Checkbox
            classes={{ root: classes.addOnCheckbox }}
            checked={addProScans}
            onChange={() => {
              props.onChange({
                addProScans: !addProScans,
                add4KScans: false
              });
              setAddProScans(!addProScans);
              setAdd4KScans(false);
            }}
            icon={<CheckCircle />}
            checkedIcon={<CheckCircleChecked className={classes.checked} />}
          />
          <Typography variant="body1" classes={{ root: classes.addOnLabel }}>
            Pro Scans
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

AddOns.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
