import { first, get, isEmpty, findIndex } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  handle: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '14px',
    marginTop: '12px',
    textTransform: 'lowercase',
    zIndex: 2
  },
  img: {
    cursor: 'pointer',
    width: '100%'
  },
  imgRoot: {
    maxWidth: props => `${props.maxWidth}%`,
    position: 'absolute',
    top: props => `${props.top}px`,
    transition: props => `top ${props.transitionTime}ms linear`
  },
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: props => props.align,
    position: 'relative'
  }
}));

export default function CommunitySubmissionsLane(props) {
  const [submission, setSubmission] = useState(null);
  const rootRef = useRef();
  const imgRef = useRef();
  const [transitionTime, setTransitionTime] = useState(60000);
  const [timingInterval, setTimingInterval] = useState(null);
  const [top, setTop] = useState();
  const classes = useStyles({
    ...props,
    top,
    transitionTime
  });

  useEffect(() => {
    if (!imgRef.current) return;
    if (timingInterval) clearInterval(timingInterval);

    const interval = setInterval(() => {
      const next = getNext();
      const img = new Image();

      img.src = next.scan.midResOptimized.read;
      img.onload = () => {
        const height = getImgHeight(img);
        setTop(-height);
        setSubmission(null);

        setTimeout(() => {
          setTransitionTime(makeTransitionTime(-height));
          setSubmission(next);
          setTop(getEndTop());
        }, props.transitionInterval);
      };
    }, transitionTime);

    setTimingInterval(interval);

    return () => clearInterval(interval);
  }, [imgRef.current, transitionTime]);

  useEffect(() => {
    if (!isEmpty(submission) || isEmpty(props.submissions)) return;

    setTop(props.start);
    setSubmission(first(props.submissions));
    setTransitionTime(makeTransitionTime(props.start));

    setTimeout(() => {
      setTop(getEndTop());
    }, props.transitionInterval);
  }, [props.submissions]);

  function getNext() {
    const subIndex = findIndex(props.submissions, s => s.id === submission.id);
    const nextIndex =
      subIndex + 1 > props.submissions.length - 1 ? 0 : subIndex + 1;

    return props.submissions[nextIndex];
  }

  function getEndTop() {
    if (!rootRef.current) return 0;

    return rootRef.current.getBoundingClientRect().height;
  }

  function getImgHeight(img) {
    const ratio = img.height / img.width;
    const imgWidth =
      (props.maxWidth / 100) * rootRef.current.getBoundingClientRect().width;

    return imgWidth * ratio;
  }

  function makeTransitionTime(start) {
    const end = getEndTop();

    return (end - start) * props.transitionInterval;
  }

  return (
    <Grid
      classes={{ root: classes.root }}
      item
      sm={props.gridSize}
      ref={rootRef}
    >
      {submission && (
        <Grid
          container
          classes={{ root: classes.imgRoot }}
          ref={imgRef}
          key={get(submission, 'publicLinks.midResOptimized.read')}
        >
          <img
            className={classes.img}
            src={get(submission, 'publicLinks.midResOptimized.read')}
            onClick={() => props.onOpenImg(submission)}
          />
          <Typography
            classes={{ root: classes.handle }}
            onClick={() => props.onClickHandle(submission)}
          >
            {props.formatHandle(get(submission, 'instagram'))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

CommunitySubmissionsLane.propTypes = {
  align: PropTypes.string.isRequired,
  formatHandle: PropTypes.func.isRequired,
  gridSize: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  onClickHandle: PropTypes.func.isRequired,
  onOpenImg: PropTypes.func.isRequired,
  start: PropTypes.number.isRequired,
  submissions: PropTypes.array.isRequired,
  transitionInterval: PropTypes.number.isRequired
};
