import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import LightRoomIcon from '../../../components/MembershipOptions/LightRoomIcon';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  getStarted: {
    background: 'transparent',
    border: `1px solid ${theme.palette.bg.main}`,
    borderRadius: `32px`,
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    fontWeight: '700',
    letterSpacing: '0.16em',
    padding: '14px 0px',
    width: '260px',
    '&:hover': {
      color: theme.palette.primary.main,
      background: `linear-gradient(180deg, #F0EFEC 25%, #FFFFFF 100%)`
    }
  },
  bg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'absolute',
    padding: '16px 16px 8px 16px',
    zIndex: 1
  },
  bgImg: {
    borderRadius: '24px',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    objectPosition: 'top center',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  },
  body: {
    zIndex: 2,
    padding: '96px 0px'
  },
  icon: {
    marginBottom: '12px'
  },
  onDemand: {
    color: theme.palette.bg.main,
    fontSize: '28px',
    marginBottom: '18px'
  },
  root: {
    background: theme.palette.bg.main,
    position: 'relative'
  },
  subtitle: {
    color: theme.palette.bg.main,
    fontSize: '32px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '48px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px'
    }
  },
  title: {
    color: theme.palette.bg.main,
    fontSize: '12px',
    lineHeight: '20px',
    margin: '0px 0px 12px 0px'
  }
}));

export default function OnDemand(props) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid item classes={{ root: classes.bg }}>
        <img
          className={classes.bgImg}
          src="https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/New%20Landing/on_demand.jpg"
        />
      </Grid>
      <Grid
        container
        classes={{ root: classes.body }}
        alignItems="center"
        direction="column"
      >
        <Typography variant="h5" classes={{ root: classes.title }}>
          Pay as you go
        </Typography>
        <Typography variant="h4" classes={{ root: classes.subtitle }}>
          Or One roll at a time
        </Typography>

        <LightRoomIcon
          classes={{ root: classes.icon }}
          withBorder
          bg="transparent"
          color="bg"
        />

        <Typography variant="h1" classes={{ root: classes.onDemand }}>
          On Demand
        </Typography>

        <Button
          variant="contained"
          classes={{ root: classes.getStarted }}
          onClick={() => props.history.push('/signup')}
        >
          Get Started - $17.99 / roll
        </Button>
      </Grid>
    </Grid>
  );
}

OnDemand.propTypes = {
  screenSize: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
