import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import NumRollsInput from './NumRollsInput';
import AddOns from './AddOns';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '48px 0px 72px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 0px 36px 0px'
    }
  }
}));

export default function PriceCalculatorForm(props) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} container>
      <NumRollsInput
        numRolls={props.inputs.numRolls.devScan}
        onChange={props.onChange}
      />
      <AddOns inputs={props.inputs} onChange={props.onChange} />
    </Grid>
  );
}

PriceCalculatorForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
