import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { get, isEmpty } from 'lodash';
import FlagSVG from '../../../components/FlagSVG/FlagSVG';

const useStyles = makeStyles(theme => ({
  flag: {
    height: '64px',
    transform: 'rotate(270deg) translate(0px, 21px)',
    [theme.breakpoints.down('md')]: {
      height: '44px',
      transform: 'rotate(270deg) translate(0px, 10px)'
    },
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      transform: 'rotate(270deg) translate(0px, 22px)'
    }
  },
  handle: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'lowercase'
  },
  src: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    paddingRight: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px'
    }
  },
  name: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    marginTop: '18px'
  },
  srcRoot: {
    maxHeight: props => (props.review?.vertical ? '200px' : 'auto'),
    maxWidth: props => (props.review?.vertical ? '300px' : 'auto'),
    overflow: 'hidden',
    position: 'relative'
  },
  quote: {
    fontSize: '28px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    }
  },
  root: {
    width: props => (props.review?.vertical ? '360px' : '716px'),
    cursor: 'grab',
    flexDirection: props => (props.review?.vertical ? 'column' : 'row'),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '320px'
    }
  },
  video: {
    height: '200px',
    width: '360px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer'
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '68px',
    height: '48px',
    backgroundColor: '#ff0000',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.1s',
    '&:hover': {
      backgroundColor: '#cc0000'
    },
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
      borderLeft: '18px solid white',
      marginLeft: '4px'
    }
  }
}));

const LazyVideo = function LazyVideo(props) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    const observer = new IntersectionObserver(
      function(entries) {
        setIsIntersecting(entries[0].isIntersecting);
      },
      {
        rootMargin: '50px'
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return function cleanup() {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  function getVideoId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  const videoId = getVideoId(props.src);
  const thumbnailUrl = videoId
    ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
    : '';

  function handleLoad() {
    setIsLoaded(true);
  }

  if (!isLoaded && !isIntersecting) {
    return (
      <div ref={videoRef} className={classes.video}>
        <img
          src={thumbnailUrl}
          alt={props.title || 'Video thumbnail'}
          className={classes.thumbnail}
          onClick={handleLoad}
        />
        <div className={classes.playButton} onClick={handleLoad} />
      </div>
    );
  }

  return (
    <iframe
      ref={videoRef}
      width={props.width}
      height={props.height}
      src={`${props.src}${props.src.includes('?') ? '&' : '?'}autoplay=1`}
      title={props.title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
};

LazyVideo.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string
};

LazyVideo.defaultProps = {
  width: '300',
  height: '200',
  title: 'YouTube video player'
};

function Review(props) {
  const classes = useStyles({ review: props.review });
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) return;
    props.onHeightChange(rootRef.current.getBoundingClientRect().height);
  }, [rootRef.current, props.screenSize.width]);

  function onClickHandle() {
    const handle = get(props.review, 'handle', 'nicefilmclub');
    window.open(
      `https://www.instagram.com/${handle.replace('@', '')}`,
      '_blank'
    );
  }

  function formatHandle(handle) {
    if (isEmpty(handle)) return `@nicefilmclub`;
    return `@${handle.replace('@', '')}`;
  }

  return (
    <Grid classes={{ root: classes.root }} container ref={rootRef}>
      <Grid item xs={12} classes={{ root: classes.srcRoot }}>
        {props.review?.video ? (
          <LazyVideo
            src={props.review.src}
            title={`${props.review.name}'s review`}
          />
        ) : (
          <img
            className={classes.src}
            src={props.review.src}
            alt={props.review.name}
          />
        )}
      </Grid>
      <Grid item xs={12} classes={{ root: classes.testimonial }}>
        <Grid container direction="column">
          <FlagSVG className={classes.flag} />
          <Typography variant="h1" classes={{ root: classes.quote }}>
            {`"${props.review.quote}"`}
          </Typography>
          <Typography variant="h6" classes={{ root: classes.name }}>
            {props.review.name}
          </Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.handle }}
            onClick={onClickHandle}
          >
            {formatHandle(props.review.handle)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Review.propTypes = {
  onHeightChange: PropTypes.func.isRequired,
  screenSize: PropTypes.object.isRequired,
  review: PropTypes.shape({
    video: PropTypes.bool,
    src: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handle: PropTypes.string,
    vertical: PropTypes.bool
  }).isRequired
};

export default Review;
