import { isNumber } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import NFCTextField from '../../../components/NFCTextField/NFCTextField';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  inputAdornment: {
    background: theme.palette.secondary.light,
    borderRadius: '50%',
    height: '8px',
    width: '8px'
  },
  inputRoot: {
    background: 'rgba(156, 146, 136, 0.24)',
    borderRadius: '100px',
    color: theme.palette.bg.main,
    backdropFilter: 'blur(24px)',
    padding: '0px 12px',
    height: '48px',
    width: '360px',
    [theme.breakpoints.down('md')]: {
      width: '300px'
    }
  },
  label: {
    color: theme.palette.bg.main,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.04em',
    margin: '18px 0px',
    textTransform: 'uppercase'
  },
  root: {}
}));

export default function NumRollsInput(props) {
  const classes = useStyles();
  const [numRolls, setNumRolls] = useState(props.numRolls);

  function onChange(event) {
    if (!isNumber(+event.target.value) || isNaN(+event.target.value)) return;

    setNumRolls(+event.target.value);
    props.onChange({
      numRolls: {
        devScan: +event.target.value,
        withRush: +event.target.value,
        withCutSleeving: +event.target.value,
        withUncutSleeving: +event.target.value,
        withPrints: +event.target.value,
        with4KScans: +event.target.value,
        withProScans: +event.target.value
      }
    });
  }

  return (
    <Grid container classes={{ root: classes.root }}>
      <Typography variant="h4" classes={{ root: classes.label }}>
        How many rolls do you shoot per year?
      </Typography>

      <NFCTextField
        inputClasses={{
          root: classes.inputRoot
        }}
        value={numRolls}
        InputProps={{
          onChange: onChange,
          disableUnderline: true,
          endAdornment: <span className={classes.inputAdornment} />
        }}
      />
    </Grid>
  );
}

NumRollsInput.propTypes = {
  numRolls: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
