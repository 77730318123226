import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {},
  tier: {
    fontSize: '28px',
    cursor: 'pointer',
    visibility: 'hidden',
    padding: '12px 24px',
    [theme.breakpoints.down('md')]: {
      visibility: props => (props.hideTiers ? 'hidden' : 'visible')
    },
    '&:hover': {
      fontStyle: 'italic',
      textDecoration: 'underline'
    }
  },
  title: {
    alignItems: 'left',
    width: '100%',
    padding: '12px 48px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 0px 12px 24px'
    }
  }
}));

export default function BenefitRowHeader(props) {
  const classes = useStyles({ hideTiers: props.hideTiers });

  return (
    <Grid container alignItems="center">
      <Grid item xs={1.8} sm={2.4}>
        <Typography variant="h1" classes={{ root: classes.title }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography
          variant="h1"
          classes={{ root: classes.tier }}
          align="center"
          color="gray"
        >
          Non-Member
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography
          variant="h1"
          classes={{ root: classes.tier }}
          align="center"
        >
          Nice
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography
          variant="h1"
          classes={{ root: classes.tier }}
          align="center"
        >
          Very Nice
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography
          variant="h1"
          classes={{ root: classes.tier }}
          align="center"
        >
          Super Nice
        </Typography>
      </Grid>
    </Grid>
  );
}

BenefitRowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hideTiers: PropTypes.bool
};

BenefitRowHeader.defaultProps = {
  hideTiers: false
};
